import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { log } from "../../utils";

const Breadcrumb = (props) => {
	const { catHeading, title, products, adClass, container, type = "normal", brokerContainer, pagination, productId, removePadding, traderHeading, listingProducts, category, ...parent } = props;
	const location = useLocation();
	let path = [];
	let x, currentProducts;

	for (x in parent) {
		if ("function" !== typeof parent[x]) path.push(parent[x]);
	}

	currentProducts = products.filter((item) => item.id === parseInt(productId));

	// get product for prev button.

	// get product for next button.

	// Managing Listing Pagination
	let productCountPerPage = pagination && pagination.current_page * pagination && pagination.per_page - pagination && pagination.per_page - listingProducts.length;
	log("Pagination", pagination, productCountPerPage);

	return (
		<nav
			aria-label="breadcrumb"
			className={`breadcrumb-nav ${adClass}`}>
			{type === "normal" ? (
				<div className={brokerContainer || container ? `container-fluid ${removePadding}` : "container"}>
					<div className="d-flex justify-content-between align-items-center">
						<ol className="breadcrumb">
							{!location.pathname.includes("trader-admin") && !category && (
								<li className="breadcrumb-item">
									<Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
								</li>
							)}
							{!location.pathname.includes("trader-admin") && category && (
								<li className="breadcrumb-item">
									<Link to={`/explore-videos`}>Explore Videos</Link>
								</li>
							)}
							{location.pathname.includes("trader-admin") && (
								<li className="breadcrumb-item">
									<Link to={`${process.env.PUBLIC_URL}/trader/trader-admin/listing`}>Listing</Link>
								</li>
							)}
							{location.pathname.includes("trader-admin") && (
								<li className="breadcrumb-item">
									<Link to={`${process.env.PUBLIC_URL}/trader/trader-admin/listing`}>Listing</Link>
								</li>
							)}
							{location.pathname.includes("explore-videos") && !category && (
								<li className="breadcrumb-item">
									<Link to={`${process.env.PUBLIC_URL}/trader/trader-admin/listing`}>Videos</Link>
								</li>
							)}
							<li
								className="breadcrumb-item active"
								aria-current="page">
								{title}
							</li>
						</ol>
						{catHeading && (
							<div className="d-inline-flex align-items-center">
								<h1 className="mb-0 fw-semibold heading-text-lg">{catHeading}</h1>
								{catHeading && <small className="text-muted fw-medium ml-2">(Shop)</small>}
							</div>
						)}
						{traderHeading && (
							<div className="d-inline-flex align-items-center">
								<h1 className="mb-0 fw-semibold heading-text-lg">{traderHeading}</h1>
								{traderHeading && <small className="text-muted fw-medium ml-2">(Shop)</small>}
							</div>
						)}
						{/* {traderHeading && (
							<div className="d-inline-flex align-items-center">
								<h1 className="mb-0 fw-semibold heading-text-lg">{traderHeading}</h1>
								<small className="text-muted fw-medium ml-2">(Shop)</small>
							</div>
						)} */}
					</div>
				</div>
			) : type === "product" ? (
				<div className={`${brokerContainer ? "container-fluid" : "container"}  d-flex align-items-center justify-content-between`}>
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
						</li>
						{path.map((item, index) => (
							<li
								className="breadcrumb-item"
								key={item.id}>
								<Link to={`${process.env.PUBLIC_URL}/shop`}>{item[0]}</Link>
							</li>
						))}
						<li
							className="breadcrumb-item active"
							aria-current="page">
							{currentProducts[0] ? currentProducts[0].name : title}
						</li>
					</ol>
				</div>
			) : (
				""
			)}
		</nav>
	);
};

function mapStateToProps(state) {
	return {
		products: state.data.products ? state.data.products : [],
	};
}

export default connect(mapStateToProps)(Breadcrumb);
